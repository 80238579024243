<template>
    <flex-container :direction="$screenWidth > 480 ? 'row' : 'column-reverse'" class="buttons-container">
        <slot></slot>
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer"

export default {
    name: "PublicModalButtonsContainer",
    components: { FlexContainer }
}
</script>

<style scoped lang="sass">
.buttons-container
    &::v-deep
        .button-prev
            margin-right: 10px
            @media (max-width: 480px)
                margin-right: 0
                margin-top: 10px
</style>
